* {
    margin: 0;
    padding: 0;
  }
  
  body {
    background-color: #222; /* Optional body background */
  }
  
  /* Container for the gallery */
  .container {
    width: 100%;
    height: 100%;
    margin: 50px auto; /* Some spacing from top */
  }
  
  /* Gallery header styling */
  .gallery-header {
    text-align: center;
    color: white;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  /* Flex container for thumbnails */
  .top {
    display: flex;
    width: 80vw;
    height: 80vh;
    margin: 0 auto; /* center horizontally */
    box-sizing: border-box;
    flex-wrap: wrap; /* Let thumbnails wrap on smaller screens */
  }
  
  /* Thumbnail list */
  .top ul {
    list-style: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  
  /* Each thumbnail item (25% width, 25% height -> 4x4 grid) */
  .top ul li {
    position: relative;
    float: left;
    width: 25%;
    height: 25%;
    overflow: hidden;
  }
  
  .top ul li img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  /* Overlay effect on hover */
  .top ul li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    transition: opacity 0.5s ease;
    pointer-events: none;
  }
  .top ul li:hover::before {
    opacity: 0;
  }
  
  /* Lightbox styling */
  .lightbox {
    position: fixed;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999;
    opacity: 0;
    pointer-events: none;
    transition: all 1.2s ease;
  }
  
  .lightbox img {
    max-width: 90%;
    max-height: 80%;
    position: relative;
    top: 50%;
    transform: translateY(-100%);
    transition: all 1s ease;
  }
  
  /* When the anchor is “active” (the hash matches) */
  .lightbox:target {
    opacity: 1;
    pointer-events: auto;
  }
  
  .lightbox:target img {
    transform: translateY(-50%);
  }
  